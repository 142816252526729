<template>
  <div />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { required, email } from '@validations'

export default {
  name: 'BaseUiType',
  props: {
    name: {
      type: String,
      default: () => '',
    },
    label: {
      type: String,
      default: () => '',
    },
    type: {
      type: Object,
      default: () => {},
    },
    required: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      value: '',
    }
  },
  computed: {
    getRules() {
      const rules = []
      if (this.required) rules.push('required')
      if (this.type.name === 'email') rules.push('email')
      // if (this.type.name === 'file') rules.push('max:204800')
      return rules.join('|')
    },
  },
  watch: {
    value: 'valueChange',
    file: 'valueChange',
  },
  methods: {
    valueChange(val) {
      let value = val || ''
      if (this.type.name === 'picklist' && val) value = value.value
      if (this.type.name === 'multipicklist' && val) {
        const values = []
        value.forEach(item => {
          values.push(item.value)
        })
        value = values.join(',')
      }
      if (this.type.name === 'reference' && val) value = value.id
      const { formData } = this.$store.state.portal
      formData[this.name] = value
      this.$store.commit('portal/UPDATE_FORM_DATA', formData)
    },
  },
}
</script>

<style scoped>

</style>
