<template>
  <b-form-group
    :label="label"
    :label-for="name"
  >
    <template
      v-if="label"
      slot="label"
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >*</span>
    </template>
    <validation-provider
      #default="{ errors }"
      :name="label"
      :rules="getRules"
    >
      <b-row class="d-flex align-items-center">
        <b-col cols="10">
          <b-form-file
            v-model="file"
            :name="name"
            :accept="accept"
            :state="file.size > 0"
            :disabled="loading"
            :placeholder="$t('Choose a file or drop it here...')"
            :drop-placeholder="$t('Drop file here...')"
          />
        </b-col>
        <b-col
          v-if="progress"
          cols="2"
        >
          <b-progress
            :value="progress"
            :precision="2"
            max="100"
            show-value
          />
        </b-col>
      </b-row>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import {
  BRow, BCol,
  BFormFile, BFormGroup, BProgress,
} from 'bootstrap-vue'
import Base from './Base.vue'

export default {
  name: 'FileUiType',
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BProgress,
  },
  extends: Base,
  props: {
    accept: {
      type: String,
      default: () => '*',
    },
    progress: {
      type: [String, Number],
      default: () => 0,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    file: [],
  }),
}
</script>

<style scoped>

</style>
